#filter-box {
  background-color: rgb(251, 252, 252, 0.8);
  margin-bottom: 10px;
  border-radius: 25px;
  padding: 5px 5px;
}

#filter-box button {
  color: black;
  border-color: rgb(180, 180, 180);
}

#filter-box button:hover {
  color: #c79dd7;
}

#filter-box .MuiButton-contained {
  color: black;
}

.filter-category {
  margin: 10px 0;
}

.filter-box-choices {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  flex: 1;
  gap: 10px 5px;
  color: black;
}