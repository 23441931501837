#rankings {
  display: flex;
  justify-content: center;
}

.ranking-entry, .ranking-entry-top {
  color: #4d1b7b;
  border-bottom: 1.5px solid rgb(206, 206, 206);
  padding: 0.5em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ranking-entry-top {
  color: #ef4f91;
}

.ranking-entry-num {
  margin-right: 1em;
  font-size: 1.3em;
}

