.main-element {
  background-color: rgba(46, 0, 0, 0.351);
  position: fixed;
  left: 5%;
  right: 5%;
  width: 90%;
  height: 90%;
  overflow: scroll;
  /* opacity: 0.8; */
  text-align: center;
  margin-bottom: 1rem;
  padding: 10px;
  border-radius: 25px;
}

.main-element::-webkit-scrollbar {
  display: none;
}

/* .pink-button {
  color: #000000 !important;
  background-color: #fe8a96 !important;
  border-color: #fe8a96 !important;
} */

.clickable:hover {
  cursor: pointer;
}

.loading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 2rem;
  color: black;
}

.icon {
  margin-left: 0.4rem;
}

.table td {
  vertical-align: center;
  text-align: center;
}

.summaries {
  position: fixed;
  left: 20%;
  right: 20%;
  width: 60%;
  height: 90%;
  overflow: scroll;
  opacity: 0.8;
  text-align: center;
}

.summaries::-webkit-scrollbar {
  display: none;
}

.input-row {
  width: 100%;
  display: flex;
}

.input-row div {
  flex-grow: 1;
}

.input-button-row {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.row-control-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.row-control-group .row-control {
  margin-left: 10px;
  margin-bottom: 7px;
  opacity: 1;
  max-width: 150px;
  min-width: 10px;
  max-height: 50px;
}

.row-control-group label {
  margin: 0;
  color: white;
}
