body, html {
  height: 100%;
  margin: 0;
  overflow: hidden;
}

body {
  background-image: url("img/backgrounds/test.jpg"); 
  background-attachment: fixed;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;;
  /* Full height */
  height: 100%;
  width: 100%;
}