@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
    background-color: rgb(251, 252, 252, 0.8);
    max-width: 60%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    border-radius: 5px;
  }

  .Login form {
    max-width: 320px;
  }
}

.button-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.button-group button {
  flex-grow: 1;
  opacity: 1;
  margin: 0 10px 10px 10px;
}