#add-new-entry-form {
  display: flex;
  flex-direction: column;
}

#step-title {
  margin-bottom: 15px;
}

#search-type-input {
  margin-left: 15px;
}

#link-selector {
  display: flex;
  flex-direction: column;
}

#link-selector #nav-buttons {
  justify-self: end;
}

#genre-selector {
  display: flex;
  flex-direction: row;
}

.new-entry-step {
  display: flex;
  flex-direction: column;
}

#nav-buttons {
  align-self: flex-end;
}
